import * as Sentry from "@sentry/vue";

export async function wsControllerConnection({ url }) {
  try {
    return new WebSocket(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
}
