import Vue from "vue";
import VueRouter from "vue-router";
import { guardMaster } from "@/router/navigationGuards";
import CompanyRoutes from "@/router/childRoutes/CompanyRoutes";
import controllersRoutes from "@/router/childRoutes/controllersRoutes";
import DealerRoutes from "@/router/childRoutes/DealerRoutes";
import KindergartenRoutes from "@/router/childRoutes/KindergartenRoutes";
import objectRoutes from "@/router/childRoutes/objectRoutes";
import serviceOrganizationRoutes from "@/router/childRoutes/serviceOrganizationRoutes";
import userGroupRoutes from "@/router/childRoutes/userGroupRoutes";
import ChildrenGroupsRoutes from "@/router/childRoutes/ChildrenGroupsRoutes";
import PersonnelRoutes from "@/router/childRoutes/PersonnelRoutes";
import ChildrenRoutes from "@/router/childRoutes/ChildrenRoutes";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(
        /* webpackChunkName: "authLogin" */ "../views/authPages/LoginPages/LoginPage.vue"
      ),
    meta: {
      requiresAuth: false
    }
  },

  {
    path: "/startRecovery",
    name: "StartRecovery",
    component: () =>
      import(
        /* webpackChunkName: "authLogin" */ "../views/authPages/RecoveryPages/RecoveryStartPage.vue"
      ),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/codeRecovery",
    name: "CodeRecovery",
    component: () =>
      import(
        /* webpackChunkName: "authLogin" */ "../views/authPages/RecoveryPages/RecoveryCodePage.vue"
      ),
    meta: {
      requiresAuth: false,
      recoveryStatus: 1
    }
  },
  {
    path: "/finishRecovery",
    name: "FinishRecovery",
    component: () =>
      import(
        /* webpackChunkName: "authLogin" */ "../views/authPages/RecoveryPages/RecoveryFinishPage.vue"
      ),
    meta: {
      requiresAuth: false,
      recoveryStatus: 2
    }
  },
  {
    path: "/startRegistration",
    name: "StartRegistration",
    component: () =>
      import(
        /* webpackChunkName: "authLogin" */ "../views/authPages/RegistrationPages/RegistrationStartPage.vue"
      ),
    meta: {
      requiresAuth: false,
      registrationStatus: 0
    }
  },
  {
    path: "/cityRegistration",
    name: "CityRegistration",
    component: () =>
      import(
        /* webpackChunkName: "authLogin" */ "../views/authPages/RegistrationPages/RegistrationCityPage"
      ),
    meta: {
      requiresAuth: false,
      registrationStatus: 1
    }
  },
  {
    path: "/objectRegistration",
    name: "ObjectRegistration",
    component: () =>
      import(
        /* webpackChunkName: "authLogin" */ "../views/authPages/RegistrationPages/RegistrationObjectPage"
      ),
    meta: {
      requiresAuth: false,
      registrationStatus: 2
    }
  },
  {
    path: "/accountRegistration",
    name: "AccountRegistration",
    component: () =>
      import(
        /* webpackChunkName: "authLogin" */ "../views/authPages/RegistrationPages/RegistrationAccountPage"
      ),
    meta: {
      requiresAuth: false,
      registrationStatus: 3
    }
  },
  {
    path: "/checkPhoneRegistration",
    name: "CheckPhoneRegistration",
    component: () =>
      import(
        /* webpackChunkName: "authLogin" */ "../views/authPages/RegistrationPages/RegistrationPhoneCheckPage"
      ),
    meta: {
      requiresAuth: false,
      registrationStatus: 4
    }
  },
  {
    path: "/codeRegistration",
    name: "CodeRegistration",
    component: () =>
      import(
        /* webpackChunkName: "authLogin" */ "../views/authPages/RegistrationPages/RegistrationCodePage"
      ),
    meta: {
      requiresAuth: false,
      registrationStatus: 5
    }
  },
  {
    path: "/finishRegistration",
    name: "FinishRegistration",
    component: () =>
      import(
        /* webpackChunkName: "authLogin" */ "../views/authPages/RegistrationPages/RegistrationFinishPage"
      ),
    meta: {
      requiresAuth: false,
      registrationStatus: 6
    }
  },
  {
    name: "Dealer",
    path: "/dealer",
    component: () => import("@/views/MainPages/DealerPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Company",
    path: "/company",
    component: () => import("@/views/MainPages/CompanyPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Objects",
    path: "/objects",
    component: () => import("@/views/MainPages/ObjectsPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Kindergartens",
    path: "/kindergartens",
    component: () => import("@/views/MainPages/KindergartensPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Users",
    path: "/users",
    component: () => import("@/views/MainPages/UsersPage"),
    meta: {
      requiresAuth: true
    }
  },

  {
    name: "UsersKindergarten",
    path: "/usersKindergarten",
    component: () => import("@/views/MainPages/UsersPageKindergarten"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "UsersKindergarten",
    path: "/usersKindergarten/:id",
    component: () => import("@/views/UsersPages/UsersInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "PersonnelControl",
    path: "/personnelControl",
    component: () => import("@/views/MainPages/PersonnelControlPages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Controllers",
    path: "/controllers",
    component: () => import("@/views/MainPages/ControllersPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Cards",
    path: "/cards",
    component: () => import("@/views/MainPages/CardsPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Accounts",
    path: "/accounts",
    component: () => import("@/views/MainPages/AccountsPage"),
    meta: {
      requiresAuth: true
    }
  },

  {
    name: "Payments",
    path: "/payment",
    component: () => import("@/views/MainPages/PaymentsPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ServesOrganization",
    path: "/serviceOrganization",
    component: () => import("@/views/MainPages/ServiceOrganizationsPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ServiceTask",
    path: "/ServiceTask",
    component: () => import("@/views/MainPages/ServiceTasksPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Notification",
    path: "/notification",
    component: () => import("@/views/MainPages/MessagesPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "DealerInfo",
    path: "/dealer/:id",
    component: () => import("@/views/DealersPages/DealerInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ServiceTaskInfo",
    path: "/ServiceTask/:id",
    component: () => import("@/views/ServiceTasksPages/ServiceTaskInfosPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "EditServiceTaskInfoId",
    path: "/editServiceTask/:id",
    component: () => import("@/views/ServiceTasksPages/EditServiceTaskPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "EditServiceTaskInfo",
    path: "/editServiceTask",
    component: () => import("@/views/Redirect/RedirectPage")
  },
  {
    name: "CompanyInfo",
    path: "/company/:id",
    component: () => import("@/views/CompanyPages/CompanyInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "CompanyEditId",
    path: "/companyEdit/:id",
    component: () => import("@/views/CompanyPages/EditCompanyPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "CompanyEdit",
    path: "/companyEdit",
    component: () => import("@/views/Redirect/RedirectPage")
  },
  {
    name: "KindergartenEditId",
    path: "/kindergartenEdit/:id",
    component: () => import("@/views/KindergartensPages/EditKindergartenPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "KindergartenEdit",
    path: "/kindergartenEdit",
    component: () => import("@/views/Redirect/RedirectPage")
  },
  {
    name: "ObjectsInfo",
    path: "/objects/:id",
    component: () => import("@/views/ObjectsPages/ObjectsInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ObjectsEditId",
    path: "/objectsEdit/:id",
    component: () => import("@/views/ObjectsPages/EditObjectPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ObjectsEdit",
    path: "/objectsEdit",
    component: () => import("@/views/Redirect/RedirectPage")
  },
  {
    name: "KindergartensInfo",
    path: "/kindergartens/:id",
    component: () =>
      import("@/views/KindergartensPages/KindergartensInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ControllersInfo",
    path: "/controllers/:id",
    component: () => import("@/views/ControllersPages/ControllersInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ControllersEditId",
    path: "/controllersEdit/:id",
    component: () => import("@/views/ControllersPages/EditControllerPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ControllersEdit",
    path: "/controllersEdit",
    component: () => import("@/views/Redirect/RedirectPage")
  },
  {
    name: "CardsInfo",
    path: "/cards/card/:id",
    component: () => import("@/views/CardsPages/CardsInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "PaymentInfo",
    path: "/payment/:id",
    component: () => import("@/views/PaymentsPages/PaymentsInfoPages.vue"),
    meta: {
      requiresAuth: true
    }
  },

  {
    name: "UserGroupsInfo",
    path: "/userGroups/:id",
    component: () => import("@/views/UserGroupsPages/UserGroupsInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "UserGroupsEditId",
    path: "/userGroupsEdit/:id",
    component: () => import("@/views/UserGroupsPages/EditUserGroupPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "UserGroupsEdit",
    path: "/userGroupsEdit",
    component: () => import("@/views/Redirect/RedirectPage")
  },
  {
    name: "UsersEditId",
    path: "/usersEdit/:id",
    component: () => import("@/views/UsersPages/EditUserPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "usersGeneralEditId",
    path: "/usersGeneralEdit/:id",
    component: () => import("@/views/UsersPages/EditUserGeneralPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "usersGeneralEdit",
    path: "/usersGeneralEdit",
    component: () => import("@/views/Redirect/RedirectPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "usersObjectsEdit",
    path: "/usersObjectsEdit/:id",
    component: () => import("@/views/UsersPages/EditUserPageFromObjects.vue"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "UsersEdit",
    path: "/usersEdit",
    component: () => import("@/views/Redirect/RedirectPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "usersObjectsEdit",
    path: "/usersObjectsEdit",
    component: () => import("@/views/Redirect/RedirectPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "UsersInfo",
    path: "/users/:id",
    component: () => import("@/views/UsersPages/UsersInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "UsersCreate",
    path: "/createUser",
    component: () => import("@/views/UsersPages/CreateUserPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "UsersCreateObjects",
    path: "/createUserObjects",
    component: () => import("@/views/UsersPages/CreateUserPageFromObjects.vue"),
    meta: {
      requiresAuth: true
    },
    props: true
  },

  {
    name: "ServesOrganizationInfo",
    path: "/serviceOrganization/:id",
    component: () =>
      import("@/views/ServesOrganizationPages/ServiceOrganizationInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "CreateDealer",
    path: "/createDealer",
    component: () => import("@/views/DealersPages/CreateDealerPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "CreateKindergartens",
    path: "/createKindergartens",
    component: () =>
      import("@/views/KindergartensPages/CreateKindergartenPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "CreateServiceOrganization",
    path: "/createServiceOrganization",
    component: () =>
      import("@/views/ServesOrganizationPages/CreateServiceOrganization"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "CreateServiceTaskPage",
    path: "/createServiceTaskPage",
    component: () => import("@/views/ServiceTasksPages/CreateServiceTaskPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "CreateCardPage",
    path: "/createCardPage",
    component: () => import("@/views/CardsPages/CreateCardPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CreateAccountPage",
    path: "/createAccountPage",
    component: () => import("@/views/AccountsPages/CreateAccountPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "CreateCompany",
    path: "/createCompany",
    component: () => import("@/views/CompanyPages/CreateCompanyPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "CreateObject",
    path: "/createObject",
    component: () => import("@/views/ObjectsPages/CreateObjectPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CreateController",
    path: "/createController",
    component: () => import("@/views/ControllersPages/ControllerCreatePage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CreatePromocodes",
    path: "/createPromocodes",
    component: () => import("@/views/Promocode/PromocodeCreatePage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "Staff",
    path: "/staff",
    component: () => import("@/views/MainPages/StaffPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "StaffInfo",
    path: "/staff/:id",
    component: () => import("@/views/StaffPages/StaffInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "CreateStaff",
    path: "/createStaff",
    component: () => import("@/views/TechniciansPages/CreateTechnicianPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "CreateUserGroup",
    path: "/createUserGroup",
    component: () => import("@/views/UserGroupsPages/CreateUserGroupPage"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "Profile",
    path: "/profile",
    component: () => import("@/views/MainPages/ProfilePage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "GeneralInformation",
    path: "/generalInformation",
    component: () => import("@/views/MainPages/GeneralInformationPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ChildrenGroups",
    path: "/childrenGroups",
    component: () => import("@/views/MainPages/ChildrenGroupsPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ChildrenGroupsInfo",
    path: "/childrenGroups/:id",
    component: () =>
      import("@/views/ChildrenGroupsPages/ChildrenGroupsInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ChildrenGroupsCreate",
    path: "/childrenGroupsCreate",
    component: () =>
      import("@/views/ChildrenGroupsPages/ChildrenGroupsCreatePage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ChildrenGroupsEditId",
    path: "/childrenGroupEdit/:id",
    component: () =>
      import("@/views/ChildrenGroupsPages/EditChildrenGroupPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ChildrenGroupsEdit",
    path: "/childrenGroupEdit",
    component: () => import("@/views/Redirect/RedirectPage")
  },
  {
    name: "ManageressCreate",
    path: "/manageressCreate",
    component: () =>
      import("@/views/ChildrenGroupsPages/ManageressCreateFormPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Children",
    path: "/children",
    component: () => import("@/views/MainPages/ChildrenPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "CreateChildren",
    path: "/createChildren",
    component: () => import("@/views/ChildrenPages/ChildrenCreateFormPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ChildrenVisitLogPage",
    path: "/childrenVisitLogPage",
    component: () => import("@/views/MainPages/ChildrenVisitLogPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ChildrenInfo",
    path: "/children/:id",
    component: () => import("@/views/ChildrenPages/ChildrenInfosPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ChildrenEditId",
    path: "/childrenEdit/:id",
    component: () => import("@/views/ChildrenPages/EditChildren"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ChildrenEdit",
    path: "/childrenEdit",
    component: () => import("@/views/Redirect/RedirectPage")
  },
  {
    name: "PassageLog",
    path: "/passageLog",
    component: () => import("@/views/MainPages/PassageLogPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Personnel",
    path: "/personnel",
    component: () => import("@/views/MainPages/PersonnelPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "PersonnelInfo",
    path: "/personnel/:id",
    component: () => import("@/views/PersonnelPages/personnelInfoPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "PersonnelEditId",
    path: "/personnelEdit/:id",
    component: () => import("@/views/UsersPages/EditUserPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "PersonnelEdit",
    path: "/personnelEdit",
    component: () => import("@/views/Redirect/RedirectPage")
  },
  {
    name: "PersonnelCreate",
    path: "/personnelCreate",
    component: () => import("@/views/PersonnelPages/personnelCreatePage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ProfileEdit",
    path: "/profile/edit",
    component: () => import("@/views/ProfilePages/EditProfilePage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "WorkingTimeSetting",
    path: "/workingTimeSetting",
    component: () => import("@/views/PersonnelControl/WorkingTimeSetting"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "MyObjects",
    path: "/myObjects",
    component: () => import("@/views/MainPages/MyObjectsPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "MyPayments",
    path: "/payments",
    component: () => import("@/views/MainPages/MyPaymentsPage.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ServiceGroups",
    path: "/serviceGroups",
    component: () => import("@/views/MainPages/ServiceGroupsPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ServiceGroupCreate",
    path: "/createServiceGroup",
    component: () =>
      import("@/views/ServiceGroupsPages/CreateServiceGroupPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "EditServiceGroupId",
    path: "/editServiceGroup/:id",
    component: () => import("@/views/ServiceGroupsPages/EditServiceGroupPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "EditServiceGroup",
    path: "/editServiceGroup",
    component: () => import("@/views/Redirect/RedirectPage")
  },
  {
    name: "ServiceGroupInfo",
    path: "/serviceGroups/:id",
    component: () => import("@/views/ServiceGroupsPages/ServiceGroupInfosPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "AboutCompany",
    path: "/AboutCompany",
    component: () => import("@/views/MainPages/AboutCompany"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "AboutDiller",
    path: "/AboutDiller",
    component: () => import("@/views/MainPages/AboutCompany"),
    meta: {
      requiresAuth: true
    }
  },

  {
    name: "createAdmin",
    path: "/createAdmin",
    component: () => import("@/views/AdminsPage/CreateAdminPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "PersonnelCheck",
    path: "/PersonnelCheck",
    component: () => import("@/views/MainPages/AboutCompany"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "EditParentId",
    path: "/editParent/:id",
    component: () => import("@/views/ParentPage/EditParent"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "EditParent",
    path: "/editParent",
    component: () => import("@/views/Redirect/RedirectPage")
  },
  {
    name: "ParentInfos",
    path: "/parentInfos/:id",
    component: () => import("@/views/ParentPage/ParentInfosPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "EditParentParenrs",
    path: "/parentInfos/:parentId/editParent/:id",
    component: () => import("@/views/ParentPage/EditParent"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ParentList",
    path: "/ParentList",
    component: () => import("@/components/info/children/Parents"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "AdminsOppen",
    path: "/adminsOppen",
    component: () => import("@/views/MainPages/AdminsOppenPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "createAdminOppen",
    path: "/createAdminOppen",
    component: () => import("@/views/AdminsOppenPage/CreateAdminPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Firmwares",
    path: "/firmwares",
    component: () => import("@/views/MainPages/ControllerFirmwaresPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "FirmwaresInfo",
    path: "/firmwares/:id",
    component: () => import("@/views/FirmwaresPages/FirmwaresInfosPages"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "FirmwareEditId",
    path: "/firmwareEdit/:id",
    component: () => import("@/views/FirmwaresPages/EditFirmwaresPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "FirmwareEdit",
    path: "/firmwareEdit",
    component: () => import("@/views/Redirect/RedirectPage")
  },
  {
    name: "createFirmware",
    path: "/createFirmware",
    component: () => import("@/views/FirmwaresPages/CreateFirmwaresPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Widgets",
    path: "/widgets",
    component: () => import("@/views/MainPages/WidgetsPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "infoPanel",
    path: "/infoPanel",
    component: () => import("@/views/MainPages/infoPanel"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Promocode",
    path: "/promocodes",
    component: () => import("@/views/MainPages/Promocodes"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Codes",
    path: "/codes",
    component: () => import("@/views/MainPages/Codes"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Access",
    path: "/access",
    component: () => import("@/views/MainPages/AccesesPage"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Review",
    path: "/review",
    component: () => import("@/views/MainPages/Review"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ReviewInfos",
    path: "/review/reviewInfos/:id",
    component: () => import("@/views/ReviewPages/ReviewInfoPages.vue"),
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   name: "Split",
  //   path: "/split",
  //   component: () => import("@/views/MainPages/SplitRate"),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  // {
  //   name: "EditSplit",
  //   path: "/editSplit",
  //   component: () => import("@/views/Split/EditSplitPage.vue"),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  // {
  //   name: "partnerPoint",
  //   path: "/partnerPoint/:id",
  //   component: () =>
  //       import("@/views/DealersPages/EditPointerPage.vue"),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    name: "Instruction",
    path: "/instruction",
    component: () => import("@/views/MainPages/instruction"),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "*",
    name: "Not Found",
    component: () => import("@/views/404Page/notFoundPage")
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

router.addRoutes(CompanyRoutes());
router.addRoutes(controllersRoutes());
router.addRoutes(DealerRoutes());
router.addRoutes(KindergartenRoutes());
router.addRoutes(objectRoutes());
router.addRoutes(serviceOrganizationRoutes());
router.addRoutes(userGroupRoutes());
router.addRoutes(ChildrenGroupsRoutes());
router.addRoutes(PersonnelRoutes());
router.addRoutes(ChildrenRoutes());

router.beforeEach((to, from, next) => {
  const guardResult = guardMaster(to, from);
  if (guardResult === true) {
    next();
  } else {
    next(guardResult);
  }
});

export default router;
