import Axios from "axios";
import { getQueriedUrl } from "@/helpers/queryStringHelper";

export async function getCitiesRequest({ search = null, ordering = null }) {
  return Axios.get(
    getQueriedUrl({
      url: "/cities/",
      query: { search, ordering: ordering || "name", limit: 2000 }
    })
  );
}
export async function getCitiesClearRequest({
  search = null,
  ordering = null
}) {
  return Axios.get(
    getQueriedUrl({
      url: "/cities/",
      query: { search, ordering: ordering || "name", limit: 2000 }
    })
  );
}

export async function getCitiesTypesClearRequest({
  search = null,
  ordering = null,
  type = null
}) {
  return Axios.get(
    getQueriedUrl({
      url: "/cities/in-use/",
      query: { search, ordering: ordering || "name", type }
    })
  );
}

export async function postCitiesRequest({ name, region }) {
  return Axios.post("/cities/", {
    name,
    region
  });
}
