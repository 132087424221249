import Vue from "vue";
import {
  createAccessGroupRequest,
  deleteAccessGroupByIdRequest,
  getAccessGroupsRequest,
  getAccessGroupByIdRequest,
  patchAccessGroupByIdRequest,
  deleteAccessTimeByIdRequest,
  patchAccessTimeByIdRequest,
  createAccessTimeRequest
} from "@/helpers/api/access-group";
import {
  createAppPriceRequest,
  patchAppPriceByIdRequest
} from "@/helpers/api/app-price";
import {
  createCardPriceRequest,
  patchCardPriceByIdRequest
} from "@/helpers/api/card-price";
import router from "@/router";
import {
  getControllerByIdRequest,
  getControllersRequest
} from "@/helpers/api/controllers";
import { getObjectClearByIdRequest } from "@/helpers/api/objects";
import moment from "moment";
import Axios from "axios";
import { csvExport } from "@/helpers/CsvHelper";
import { getKindergartenByIdRequest } from "@/helpers/api/kindergartens";

const state = {
  groupInfo: {
    results: null
  },
  pageGroupList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  createUserGroupForm: {
    duration: null,
    isMonday: false,
    isTuesday: false,
    startTime: null,
    endTime: null,
    isWednesday: false,
    isThursday: false,
    isFriday: false,
    isSaturday: false,
    isSunday: false,
    startMonday: null,
    startTuesday: null,
    startWednesday: null,
    startThursday: null,
    startFriday: null,
    startSaturday: null,
    startSunday: null,
    endMonday: null,
    endTuesday: null,
    endWednesday: null,
    endThursday: null,
    endFriday: null,
    endSaturday: null,
    endSunday: null,
    title: "",
    tariff_name: "",
    // timeType: null,
    timeType: 2, //  TODO вернуть null когда сделают функционал на бэке
    organization: null,
    object: null,
    role: 110,
    // appPrice: null,
    controllers: [],
    is_all: true,
    cardPrices: null,
    app_price_3: null,
    app_price_12: null,
    applyBaseTariff: false
  },
  editUserGroupForm: {
    startTime: { value: null, isEdit: false },
    endTime: { value: null, isEdit: false },
    duration: { value: null, isEdit: false },
    timeType: { value: null, isEdit: false },
    isMonday: { value: false, isEdit: false },
    isTuesday: { value: false, isEdit: false },
    isWednesday: { value: false, isEdit: false },
    isThursday: { value: false, isEdit: false },
    isFriday: { value: false, isEdit: false },
    isSaturday: { value: false, isEdit: false },
    isSunday: { value: false, isEdit: false },
    idMonday: { value: false, isEdit: false },
    idTuesday: { value: false, isEdit: false },
    idWednesday: { value: false, isEdit: false },
    idThursday: { value: false, isEdit: false },
    idFriday: { value: false, isEdit: false },
    idSaturday: { value: false, isEdit: false },
    idSunday: { value: false, isEdit: false },
    startMonday: { value: null, isEdit: false },
    startTuesday: { value: null, isEdit: false },
    startWednesday: { value: null, isEdit: false },
    startThursday: { value: null, isEdit: false },
    startFriday: { value: null, isEdit: false },
    startSaturday: { value: null, isEdit: false },
    startSunday: { value: null, isEdit: false },
    endMonday: { value: null, isEdit: false },
    endTuesday: { value: null, isEdit: false },
    endWednesday: { value: null, isEdit: false },
    endThursday: { value: null, isEdit: false },
    endFriday: { value: null, isEdit: false },
    endSaturday: { value: null, isEdit: false },
    endSunday: { value: null, isEdit: false },
    title: { value: "", isEdit: false },
    tariff_name: { value: "", isEdit: false },
    organization: { value: null, isEdit: false },
    object: { value: null, isEdit: false },
    role: { value: 110, isEdit: false },
    // appPrice: { value: null, isEdit: false },
    controllers: { value: [], isEdit: false },
    is_all: { value: false, isEdit: false },
    cardPrices: { value: null, isEdit: false },
    app_price_3: { value: null, isEdit: false },
    app_price_12: { value: null, isEdit: false }
  },
  objectGroupList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  organizationGroupList: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  userSearch: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  setUserSearch: {
    text: ""
  }
};

const getters = {
  getEditUserGroupForm: state => state.editUserGroupForm,
  getPageGroupsList: state => state.pageGroupList,
  getCreateUserGroupForm: state => state.createUserGroupForm,
  getGroupInfo: state => state.groupInfo,
  getObjectGroupList: state => state.objectGroupList,
  getOrganizationGroupList: state => state.organizationGroupList,
  getSetUserSearch: state => state.setUserSearch,
  getUserSearch: state => state.userSearch
};

const actions = {
  async downloadUsersGroupList(context, header) {
    const { id, query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getAccessGroupsRequest({
        query: { ...query, limit: 5000, offset: null, organization: id }
      })
        .then(response => {
          csvExport(response.data, header, "ГруппаПользователей");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async downloadObjectsUsersGroupList(context, header) {
    const { id, query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getAccessGroupsRequest({
        query: { ...query, limit: 5000, offset: null, object: id }
      })
        .then(response => {
          csvExport(response.data, header, "ГруппаПользователей");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  downloadPageGroupUsersList(context, header) {
    const { query } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getAccessGroupsRequest({ query: { ...query, limit: 5000, offset: null } })
        .then(response => {
          csvExport(response.data, header, "ГруппаПользователей");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchPageGroupsList(context) {
    const query = context.getters.getPageQuery.query;
    const ordering = context.getters.getOrdering;
    // const id = context.getters.getCurrentOrganization;
    return new Promise((resolve, reject) => {
      getAccessGroupsRequest({
        query: {
          ordering,
          ...query
        }
      })
        .then(response => {
          context.commit("setGroupList", {
            listName: "pageGroupList",
            groups: response.data
          });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchObjectUserGroupList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getAccessGroupsRequest({ query: { ...query, ordering, object: id } })
        .then(response => {
          context.commit("setGroupList", {
            listName: "objectGroupList",
            groups: response.data
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchOrganizationUserGroupList(context) {
    const { query, id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getAccessGroupsRequest({
        query: { ...query, organization: id, ordering }
      })
        .then(response => {
          context.commit("setGroupList", {
            listName: "organizationGroupList",
            groups: response.data
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchOrganizationUserGroupListNoQuery(context) {
    const { id } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getAccessGroupsRequest({
        query: { organization: id, ordering }
      })
        .then(response => {
          context.commit("setGroupList", {
            listName: "organizationGroupList",
            groups: response.data
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchOrganizationUserGroupInfoList(context) {
    const { query } = context.getters.getPageQuery;
    const ordering = context.getters.getOrdering;
    return new Promise((resolve, reject) => {
      getAccessGroupsRequest({
        query: {
          ...query,
          organization: context.rootGetters.getKindergartenId,
          ordering
        }
      })
        .then(response => {
          context.commit("setGroupList", {
            listName: "organizationGroupList",
            groups: response.data
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchUserGroupList(context) {
    const { query, id } = context.getters.getPageQuery;
    return new Promise((resolve, reject) => {
      getAccessGroupsRequest({ query: { ...query, id } })
        .then(response => {
          context.commit("setUserSearch", {
            listName: "userSearch",
            group: response.data
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async fetchUserGroupInfo(context) {
    const { id } =
      context.getters.getPageQuery ||
      context.getters.getChildrenGroupsInfo.groupa;

    return new Promise((resolve, reject) => {
      getAccessGroupByIdRequest({ id })
        .then(response => {
          context.commit("setGroupInfo", {
            listName: "groupInfo",
            groups: response.data
          });
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async clearGroupsPage(context) {
    context.commit("clearGroupList", {
      listName: "pageGroupList"
    });
    return Promise.resolve();
  },
  async createUserGroup(context) {
    const userGroupForm = context.getters.getCreateUserGroupForm;
    const childrenGroupsCreateForm =
      context.getters.getChildrenGroupsCreateForm;
    const data = {
      title: childrenGroupsCreateForm.number
        ? "Опекуны группы " + childrenGroupsCreateForm.number
        : userGroupForm.title,
      tariff_name: userGroupForm.tariff_name,
      organization: userGroupForm.object.organization,
      object: userGroupForm.object.id,
      role: userGroupForm.role,
      controllers: userGroupForm.controllers.map(el => el.id)
    };
    const baseTariff = context.getters.getDealerInfo.results?.group_config;

    if (userGroupForm.applyBaseTariff) {
      data.tariff_name = baseTariff?.tariff_name;
      data.app_price_3 = baseTariff?.app_price_3;
      data.app_price_12 = baseTariff?.app_price_12;
      data.card_prices = baseTariff?.card_price;
      data.duration = baseTariff?.card_duration;
    }
    if (userGroupForm.is_all) {
      data.controllers = (
        await getControllersRequest({
          query: { object: userGroupForm.object.id }
        })
      ).data.results.map(el => el.id);
    }
    const accessGroup = (await createAccessGroupRequest({ data })).data;
    localStorage.setItem("UserGroup", accessGroup.id);
    localStorage.setItem("UserGroupName", childrenGroupsCreateForm.name);
    localStorage.setItem("UserGroupNumber", childrenGroupsCreateForm.number);
    const small = {
      price: userGroupForm.applyBaseTariff
        ? baseTariff.app_price_3
        : userGroupForm.app_price_3,
      duration: 3,
      // discount: userGroupForm.small_discount,
      group: accessGroup.id
    };
    const normal = {
      price: userGroupForm.applyBaseTariff
        ? baseTariff.app_price_12
        : userGroupForm.app_price_12,
      duration: 12,
      // discount: userGroupForm.normal_discount,
      group: accessGroup.id
    };
    let promArr = [];
    promArr.push(createAppPriceRequest({ data: small }));
    promArr.push(createAppPriceRequest({ data: normal }));
    const card = {
      price: userGroupForm.applyBaseTariff
        ? baseTariff.card_price
        : userGroupForm.cardPrices,
      duration: userGroupForm.applyBaseTariff
        ? baseTariff.card_duration
        : userGroupForm.duration,
      group: accessGroup.id
    };
    if (userGroupForm.object.type === "kindergarten")
      promArr.push(createCardPriceRequest({ data: card }));

    const times = [];
    const monday = moment()
      .startOf("week")
      .locale("en");
    switch (userGroupForm.timeType) {
      case 1: {
        for (let i = 0; i < 7; i++) {
          times.push({
            start_time: userGroupForm.startTime + ":00",
            end_time: userGroupForm.endTime + ":00",
            weekday: monday
              .clone()
              .add(i, "day")
              .isoWeekday(),
            group: accessGroup.id
          });
        }
        break;
      }
      case 2: {
        for (let i = 0; i < 7; i++) {
          times.push({
            start_time: "00:00:00",
            end_time: "00:00:00",
            weekday: monday
              .clone()
              .add(i, "day")
              .isoWeekday(),
            group: accessGroup.id
          });
        }
        break;
      }
      case 3: {
        for (let i = 0; i < 5; i++) {
          times.push({
            start_time: userGroupForm.startTime + ":00",
            end_time: userGroupForm.endTime + ":00",
            weekday: monday
              .clone()
              .add(i, "day")
              .isoWeekday(),
            group: accessGroup.id
          });
        }
        break;
      }
      case 4: {
        if (userGroupForm.isMonday) {
          times.push({
            start_time: userGroupForm.startMonday + ":00",
            end_time: userGroupForm.endMonday + ":00",
            weekday: 1,
            group: accessGroup.id
          });
        }
        if (userGroupForm.isTuesday) {
          times.push({
            start_time: userGroupForm.startTuesday + ":00",
            end_time: userGroupForm.endTuesday + ":00",
            weekday: 2,
            group: accessGroup.id
          });
        }
        if (userGroupForm.isWednesday) {
          times.push({
            start_time: userGroupForm.startWednesday + ":00",
            end_time: userGroupForm.endWednesday + ":00",
            weekday: 3,
            group: accessGroup.id
          });
        }
        if (userGroupForm.isThursday) {
          times.push({
            start_time: userGroupForm.startThursday + ":00",
            end_time: userGroupForm.endThursday + ":00",
            weekday: 4,
            group: accessGroup.id
          });
        }
        if (userGroupForm.isFriday) {
          times.push({
            start_time: userGroupForm.startFriday + ":00",
            end_time: userGroupForm.endFriday + ":00",
            weekday: 5,
            group: accessGroup.id
          });
        }
        if (userGroupForm.isSaturday) {
          times.push({
            start_time: userGroupForm.startSaturday + ":00",
            end_time: userGroupForm.endSaturday + ":00",
            weekday: 6,
            group: accessGroup.id
          });
        }
        if (userGroupForm.isSunday) {
          times.push({
            start_time: userGroupForm.startSunday + ":00",
            end_time: userGroupForm.endSunday + ":00",
            weekday: 7,
            group: accessGroup.id
          });
        }

        break;
      }
      default: {
        break;
      }
    }

    promArr.concat(times.map(time => Axios.post("/access-times/", time)));

    const createResult = Promise.all(promArr);

    // createResult.then(() => {
    //   router.replace(`userGroups/${accessGroup.id}`).finally(() => {
    //     context.commit("clearCreateUserGroupForm");
    //   });
    // });
    return createResult;
  },

  async createUserGroupFromChildren(context) {
    const userGroupForm = context.getters.getCreateUserGroupForm;
    const childrenGroupsCreateForm =
      context.getters.getChildrenGroupsCreateForm;
    const tarifInfo = context.getters.getDealerInfo.results;
    const kinder = (await getKindergartenByIdRequest({ id: tarifInfo.id }))
      .data;

    const data = {
      title: "Опекуны группы " + childrenGroupsCreateForm.number,
      tariff_name: tarifInfo.group_config.tariff_name,
      organization: tarifInfo.id,
      object: kinder.object.id,
      role: 110,
      controllers: userGroupForm.controllers.map(el => el.id)
    };
    const objectId = context.getters.getOrganizationObjectsList.results.id;
    if (userGroupForm.is_all) {
      data.controllers = (
        await getControllersRequest({
          query: { object: objectId }
        })
      ).data.results.map(el => el.id);
    }

    const accessGroup = (await createAccessGroupRequest({ data })).data;
    localStorage.setItem("UserGroup", accessGroup.id);
    localStorage.setItem("UserGroupName", childrenGroupsCreateForm.name);
    localStorage.setItem("UserGroupNumber", childrenGroupsCreateForm.number);

    const small = {
      price: tarifInfo.group_config.app_price_3,
      duration: 3,
      // discount: tarifInfo.group_config.app_discount_3,
      group: accessGroup.id
    };
    const normal = {
      price: tarifInfo.group_config.app_price_12,
      duration: 12,
      // discount: tarifInfo.group_config.app_discount_12,
      group: accessGroup.id
    };
    let promArr = [];
    promArr.push(createAppPriceRequest({ data: small }));
    promArr.push(createAppPriceRequest({ data: normal }));
    const card = {
      price: tarifInfo.group_config.card_price,
      duration: tarifInfo.group_config.card_duration,
      group: accessGroup.id
    };
    promArr.push(createCardPriceRequest({ data: card }));

    const times = [];
    const monday = moment()
      .startOf("week")
      .locale("en");
    switch (userGroupForm.timeType) {
      case 1: {
        for (let i = 0; i < 7; i++) {
          times.push({
            start_time: userGroupForm.startTime + ":00",
            end_time: userGroupForm.endTime + ":00",
            weekday: monday
              .clone()
              .add(i, "day")
              .isoWeekday(),
            group: accessGroup.id
          });
        }
        break;
      }
      case 2: {
        for (let i = 0; i < 7; i++) {
          times.push({
            start_time: "00:00:00",
            end_time: "00:00:00",
            weekday: monday
              .clone()
              .add(i, "day")
              .isoWeekday(),
            group: accessGroup.id
          });
        }
        break;
      }
      case 3: {
        for (let i = 0; i < 5; i++) {
          times.push({
            start_time: userGroupForm.startTime + ":00",
            end_time: userGroupForm.endTime + ":00",
            weekday: monday
              .clone()
              .add(i, "day")
              .isoWeekday(),
            group: accessGroup.id
          });
        }
        break;
      }
      case 4: {
        if (userGroupForm.isMonday) {
          times.push({
            start_time: userGroupForm.startMonday + ":00",
            end_time: userGroupForm.endMonday + ":00",
            weekday: 1,
            group: accessGroup.id
          });
        }
        if (userGroupForm.isTuesday) {
          times.push({
            start_time: userGroupForm.startTuesday + ":00",
            end_time: userGroupForm.endTuesday + ":00",
            weekday: 2,
            group: accessGroup.id
          });
        }
        if (userGroupForm.isWednesday) {
          times.push({
            start_time: userGroupForm.startWednesday + ":00",
            end_time: userGroupForm.endWednesday + ":00",
            weekday: 3,
            group: accessGroup.id
          });
        }
        if (userGroupForm.isThursday) {
          times.push({
            start_time: userGroupForm.startThursday + ":00",
            end_time: userGroupForm.endThursday + ":00",
            weekday: 4,
            group: accessGroup.id
          });
        }
        if (userGroupForm.isFriday) {
          times.push({
            start_time: userGroupForm.startFriday + ":00",
            end_time: userGroupForm.endFriday + ":00",
            weekday: 5,
            group: accessGroup.id
          });
        }
        if (userGroupForm.isSaturday) {
          times.push({
            start_time: userGroupForm.startSaturday + ":00",
            end_time: userGroupForm.endSaturday + ":00",
            weekday: 6,
            group: accessGroup.id
          });
        }
        if (userGroupForm.isSunday) {
          times.push({
            start_time: userGroupForm.startSunday + ":00",
            end_time: userGroupForm.endSunday + ":00",
            weekday: 7,
            group: accessGroup.id
          });
        }

        break;
      }
      default: {
        break;
      }
    }

    promArr.concat(times.map(time => Axios.post("/access-times/", time)));

    const createResult = Promise.all(promArr);

    return createResult;
  },

  async deleteUserGroup(context, { id }) {
    router.go(-1);
    return deleteAccessGroupByIdRequest({ id });
  },
  async banUserGroup(context, { id }) {
    // router.go(-1);

    const prom = patchAccessGroupByIdRequest({
      id,
      data: { is_active: false }
    });
    prom.finally(() => {
      // router.go(-1);
      context.dispatch("fetchUserGroupInfo");
    });
    return prom;
  },
  async unBlockUserGroup(context, { id }) {
    // router.go(-1);

    const prom = patchAccessGroupByIdRequest({ id, data: { is_active: true } });
    prom.finally(() => {
      // router.go(-1);
      context.dispatch("fetchUserGroupInfo");
    });
    return prom;
    //
  },

  async prepareEditUserGroup(context, { id }) {
    const userInfo = (await getAccessGroupByIdRequest({ id })).data;
    userInfo.object = (
      await getObjectClearByIdRequest({ id: userInfo.object })
    ).data;
    userInfo.is_all = false;
    userInfo.object = {
      ...userInfo.object,
      text: userInfo.object.name,
      value: userInfo.object
    };
    // userInfo.appPrice =
    //   userInfo.appprices_details && userInfo.appprices_details.length > 0
    //     ? `${userInfo.appprices_details[0].price}`
    //     : null;

    userInfo.cardPrices =
      userInfo.cardprices_details && userInfo.cardprices_details.length > 0
        ? userInfo.cardprices_details[0].price
        : null;

    userInfo.duration =
      userInfo.cardprices_details && userInfo.cardprices_details.length > 0
        ? userInfo.cardprices_details[0].duration
        : null;

    userInfo.controllers && userInfo.controllers.length > 0
      ? (userInfo.controllers = await Promise.all(
          userInfo.controllers.map(async controller => {
            const data = (await getControllerByIdRequest({ id: controller }))
              .data;
            return { ...data, text: data.name, value: data.id };
          })
        ))
      : (userInfo.controllers = []);

    userInfo.app_price_3 =
      userInfo.appprices_details &&
      userInfo.appprices_details.length > 0 &&
      userInfo.appprices_details.find(el => el.duration === 3)
        ? userInfo.appprices_details.find(el => el.duration === 3).price
        : null;
    userInfo.app_price_12 =
      userInfo.appprices_details &&
      userInfo.appprices_details.length > 0 &&
      userInfo.appprices_details.find(el => el.duration === 12)
        ? userInfo.appprices_details.find(el => el.duration === 12).price
        : null;
    if (
      userInfo.access_time.length === 7 &&
      userInfo.access_time.reduce((result, time) => {
        if (!result) {
          return false;
        }
        result =
          time.start_time.slice(0, -3) === "00:00" &&
          time.end_time.slice(0, -3) === "00:00";
        return result;
      }, true)
    ) {
      userInfo.timeType = 2;
    } else if (
      userInfo.access_time.length === 7 ||
      userInfo.access_time.length === 5
    ) {
      const times = userInfo.access_time.reduce(
        (result, time) => {
          if (!result.results) {
            result.results = false;
            return result;
          }
          if (!result.isNormal) {
            result.startTime = time.start_time.slice(0, -3);
            result.endTime = time.end_time.slice(0, -3);
            result.isNormal = true;
          } else {
            if (
              !(
                result.startTime === time.start_time.slice(0, -3) &&
                result.endTime === time.end_time.slice(0, -3)
              )
            ) {
              result.results = false;
            }
          }
          return result;
        },
        { startTime: null, endTime: null, isNormal: false, results: true }
      );
      if (times.results) {
        userInfo.startTime = times.startTime;
        userInfo.endTime = times.endTime;
        userInfo.timeType = userInfo.access_time.length === 7 ? 1 : 3;
      }
    } else {
      userInfo.timeType = 4;
    }
    userInfo.access_time.forEach(accessTime => {
      if (accessTime.weekday === 1) {
        userInfo.idMonday = accessTime.id;
        userInfo.isMonday = true;
        userInfo.startMonday = accessTime.start_time.slice(0, -3);
        userInfo.endMonday = accessTime.end_time.slice(0, -3);
      }
      if (accessTime.weekday === 2) {
        userInfo.idTuesday = accessTime.id;
        userInfo.isTuesday = true;
        userInfo.startTuesday = accessTime.start_time.slice(0, -3);
        userInfo.endTuesday = accessTime.end_time.slice(0, -3);
      }
      if (accessTime.weekday === 3) {
        userInfo.idWednesday = accessTime.id;
        userInfo.isWednesday = true;
        userInfo.startWednesday = accessTime.start_time.slice(0, -3);
        userInfo.endWednesday = accessTime.end_time.slice(0, -3);
      }
      if (accessTime.weekday === 4) {
        userInfo.idThursday = accessTime.id;
        userInfo.isThursday = true;
        userInfo.startThursday = accessTime.start_time.slice(0, -3);
        userInfo.endThursday = accessTime.end_time.slice(0, -3);
      }
      if (accessTime.weekday === 5) {
        userInfo.idFriday = accessTime.id;
        userInfo.isFriday = true;
        userInfo.startFriday = accessTime.start_time.slice(0, -3);
        userInfo.endFriday = accessTime.end_time.slice(0, -3);
      }
      if (accessTime.weekday === 6) {
        userInfo.idSaturday = accessTime.id;
        userInfo.isSaturday = true;
        userInfo.startSaturday = accessTime.start_time.slice(0, -3);
        userInfo.endSaturday = accessTime.end_time.slice(0, -3);
      }
      if (accessTime.weekday === 7) {
        userInfo.idSunday = accessTime.id;
        userInfo.isSunday = true;
        userInfo.startSunday = accessTime.start_time.slice(0, -3);
        userInfo.endSunday = accessTime.end_time.slice(0, -3);
      }
    });

    context.commit("prepareEditUserGroup", userInfo);
  },
  async editUserGroup(context, { id }) {
    const editForm = context.getters.getEditUserGroupForm;

    if (localStorage.getItem("editUserGroupNumber")) {
      editForm.title.value =
        "Опекуны группы " + localStorage.getItem("editUserGroupNumber");
      editForm.title.isEdit = true;
    }
    let editedData = {};
    for (let key in editForm) {
      if (editForm[key].isEdit) {
        editedData[key] = editForm[key].value;
      }
    }
    if (editedData.object) editedData.object = editedData.object.id;
    if (editedData.controllers)
      editedData.controllers = editedData.controllers.map(el => el.id);
    const userGroupInfo = (await getAccessGroupByIdRequest({ id })).data;

    if (editForm.app_price_3.isEdit) {
      const small = userGroupInfo.appprices_details.find(
        el => el.duration === 3
      );
      const small_edit = {
        price: editForm.app_price_3.value,
        duration: 3,
        // discount: editForm.small_discount.value,
        group: id
      };
      small
        ? await patchAppPriceByIdRequest({ id: small.id, data: small_edit })
        : await createAppPriceRequest({ data: small_edit });
    }
    if (editForm.app_price_12.isEdit) {
      const normal = userGroupInfo.appprices_details.find(
        el => el.duration === 12
      );
      const normal_edit = {
        price: editForm.app_price_12.value,
        duration: 12,
        // discount: editForm.normal_discount.value,
        group: id
      };
      normal
        ? await patchAppPriceByIdRequest({ id: normal.id, data: normal_edit })
        : await createAppPriceRequest({ data: normal_edit });
    }
    if (editForm.cardPrices.isEdit || editForm.duration.isEdit) {
      const card =
        userGroupInfo.cardprices_details.length > 0
          ? userGroupInfo.cardprices_details[0]
          : null;

      const card_edit = {
        price: editForm.cardPrices.value,
        duration: editForm.duration.value,
        group: id
      };
      card
        ? await patchCardPriceByIdRequest({ id: card.id, data: card_edit })
        : await createCardPriceRequest({ data: card_edit });
    }

    if (editForm.timeType.value === 1) {
      if (
        editForm.startTime.isEdit ||
        editForm.endTime.isEdit ||
        editForm.timeType.isEdit
      ) {
        if (editForm.idMonday.value) {
          patchAccessTimeByIdRequest({
            id: editForm.idMonday.value,
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 1,
              group: id
            }
          });
        } else {
          createAccessTimeRequest({
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 1,
              group: id
            }
          });
        }
        if (editForm.idTuesday.value) {
          patchAccessTimeByIdRequest({
            id: editForm.idTuesday.value,
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 2,
              group: id
            }
          });
        } else {
          createAccessTimeRequest({
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 2,
              group: id
            }
          });
        }
        if (editForm.idWednesday.value) {
          patchAccessTimeByIdRequest({
            id: editForm.idWednesday.value,
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 3,
              group: id
            }
          });
        } else {
          createAccessTimeRequest({
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 3,
              group: id
            }
          });
        }
        if (editForm.idThursday.value) {
          patchAccessTimeByIdRequest({
            id: editForm.idThursday.value,
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 4,
              group: id
            }
          });
        } else {
          createAccessTimeRequest({
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 4,
              group: id
            }
          });
        }
        if (editForm.idFriday.value) {
          patchAccessTimeByIdRequest({
            id: editForm.idFriday.value,
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 5,
              group: id
            }
          });
        } else {
          createAccessTimeRequest({
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 5,
              group: id
            }
          });
        }
        if (editForm.idSaturday.value) {
          patchAccessTimeByIdRequest({
            id: editForm.idSaturday.value,
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 6,
              group: id
            }
          });
        } else {
          createAccessTimeRequest({
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 6,
              group: id
            }
          });
        }
        if (editForm.idSunday.value) {
          patchAccessTimeByIdRequest({
            id: editForm.idSunday.value,
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 7,
              group: id
            }
          });
        } else {
          createAccessTimeRequest({
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 7,
              group: id
            }
          });
        }
      }
    }
    if (editForm.timeType.value === 2) {
      if (editForm.idMonday.value) {
        patchAccessTimeByIdRequest({
          id: editForm.idMonday.value,
          data: {
            start_time: "00:00:00",
            end_time: "00:00:00",
            weekday: 1,
            group: id
          }
        });
      } else {
        createAccessTimeRequest({
          data: {
            start_time: "00:00:00",
            end_time: "00:00:00",
            weekday: 1,
            group: id
          }
        });
      }
      if (editForm.idTuesday.value) {
        patchAccessTimeByIdRequest({
          id: editForm.idTuesday.value,
          data: {
            start_time: "00:00:00",
            end_time: "00:00:00",
            weekday: 2,
            group: id
          }
        });
      } else {
        createAccessTimeRequest({
          data: {
            start_time: "00:00:00",
            end_time: "00:00:00",
            weekday: 2,
            group: id
          }
        });
      }
      if (editForm.idWednesday.value) {
        patchAccessTimeByIdRequest({
          id: editForm.idWednesday.value,
          data: {
            start_time: "00:00:00",
            end_time: "00:00:00",
            weekday: 3,
            group: id
          }
        });
      } else {
        createAccessTimeRequest({
          data: {
            start_time: "00:00:00",
            end_time: "00:00:00",
            weekday: 3,
            group: id
          }
        });
      }
      if (editForm.idThursday.value) {
        patchAccessTimeByIdRequest({
          id: editForm.idThursday.value,
          data: {
            start_time: "00:00:00",
            end_time: "00:00:00",
            weekday: 4,
            group: id
          }
        });
      } else {
        createAccessTimeRequest({
          data: {
            start_time: "00:00:00",
            end_time: "00:00:00",
            weekday: 4,
            group: id
          }
        });
      }
      if (editForm.idFriday.value) {
        patchAccessTimeByIdRequest({
          id: editForm.idFriday.value,
          data: {
            start_time: "00:00:00",
            end_time: "00:00:00",
            weekday: 5,
            group: id
          }
        });
      } else {
        createAccessTimeRequest({
          data: {
            start_time: "00:00:00",
            end_time: "00:00:00",
            weekday: 5,
            group: id
          }
        });
      }
      if (editForm.idSaturday.value) {
        patchAccessTimeByIdRequest({
          id: editForm.idSaturday.value,
          data: {
            start_time: "00:00:00",
            end_time: "00:00:00",
            weekday: 6,
            group: id
          }
        });
      } else {
        createAccessTimeRequest({
          data: {
            start_time: "00:00:00",
            end_time: "00:00:00",
            weekday: 6,
            group: id
          }
        });
      }
      if (editForm.idSunday.value) {
        patchAccessTimeByIdRequest({
          id: editForm.idSunday.value,
          data: {
            start_time: "00:00:00",
            end_time: "00:00:00",
            weekday: 7,
            group: id
          }
        });
      } else {
        createAccessTimeRequest({
          data: {
            start_time: "00:00:00",
            end_time: "00:00:00",
            weekday: 7,
            group: id
          }
        });
      }
    }
    if (editForm.timeType.value === 3) {
      if (editForm.startTime.isEdit || editForm.endTime.isEdit) {
        if (editForm.idMonday.value) {
          patchAccessTimeByIdRequest({
            id: editForm.idMonday.value,
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 1,
              group: id
            }
          });
        } else {
          createAccessTimeRequest({
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 1,
              group: id
            }
          });
        }
        if (editForm.idTuesday.value) {
          patchAccessTimeByIdRequest({
            id: editForm.idTuesday.value,
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 2,
              group: id
            }
          });
        } else {
          createAccessTimeRequest({
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 2,
              group: id
            }
          });
        }
        if (editForm.idWednesday.value) {
          patchAccessTimeByIdRequest({
            id: editForm.idWednesday.value,
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 3,
              group: id
            }
          });
        } else {
          createAccessTimeRequest({
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 3,
              group: id
            }
          });
        }
        if (editForm.idThursday.value) {
          patchAccessTimeByIdRequest({
            id: editForm.idThursday.value,
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 4,
              group: id
            }
          });
        } else {
          createAccessTimeRequest({
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 4,
              group: id
            }
          });
        }
        if (editForm.idFriday.value) {
          patchAccessTimeByIdRequest({
            id: editForm.idFriday.value,
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 5,
              group: id
            }
          });
        } else {
          createAccessTimeRequest({
            data: {
              start_time: editForm.startTime.value + ":00",
              end_time: editForm.endTime.value + ":00",
              weekday: 5,
              group: id
            }
          });
        }
        if (editForm.idSaturday.value) {
          deleteAccessTimeByIdRequest({
            id: editForm.idSaturday.value
          });
        }
        if (editForm.idSunday.value) {
          deleteAccessTimeByIdRequest({
            id: editForm.idSunday.value
          });
        }
      }
    }
    if (editForm.timeType.value === 4) {
      if (
        editForm.isMonday.isEdit ||
        editForm.startMonday.isEdit ||
        editForm.endMonday.isEdit
      ) {
        if (editForm.idMonday.value) {
          if (!editForm.isMonday.value) {
            deleteAccessTimeByIdRequest({ id: editForm.idMonday.value });
          } else {
            patchAccessTimeByIdRequest({
              id: editForm.idMonday.value,
              data: {
                start_time: editForm.startMonday.value + ":00",
                end_time: editForm.endMonday.value + ":00",
                weekday: 1,
                group: id
              }
            });
          }
        } else {
          if (editForm.isMonday.value) {
            createAccessTimeRequest({
              data: {
                start_time: editForm.startMonday.value + ":00",
                end_time: editForm.endMonday.value + ":00",
                weekday: 1,
                group: id
              }
            });
          }
        }
      }

      if (
        editForm.isTuesday.isEdit ||
        editForm.startTuesday.isEdit ||
        editForm.endTuesday.isEdit
      ) {
        if (editForm.idTuesday.value) {
          if (!editForm.isTuesday.value) {
            deleteAccessTimeByIdRequest({ id: editForm.idTuesday.value });
          } else {
            patchAccessTimeByIdRequest({
              id: editForm.idTuesday.value,
              data: {
                start_time: editForm.startTuesday.value + ":00",
                end_time: editForm.endTuesday.value + ":00",
                weekday: 2,
                group: id
              }
            });
          }
        } else {
          if (editForm.isTuesday.value) {
            createAccessTimeRequest({
              data: {
                start_time: editForm.startTuesday.value + ":00",
                end_time: editForm.endTuesday.value + ":00",
                weekday: 2,
                group: id
              }
            });
          }
        }
      }

      if (
        editForm.isWednesday.isEdit ||
        editForm.startWednesday.isEdit ||
        editForm.endWednesday.isEdit
      ) {
        if (editForm.idWednesday.value) {
          if (!editForm.isWednesday.value) {
            deleteAccessTimeByIdRequest({ id: editForm.idWednesday.value });
          } else {
            patchAccessTimeByIdRequest({
              id: editForm.idWednesday.value,
              data: {
                start_time: editForm.startWednesday.value + ":00",
                end_time: editForm.endWednesday.value + ":00",
                weekday: 3,
                group: id
              }
            });
          }
        } else {
          if (editForm.isWednesday.value) {
            createAccessTimeRequest({
              data: {
                start_time: editForm.startWednesday.value + ":00",
                end_time: editForm.endWednesday.value + ":00",
                weekday: 3,
                group: id
              }
            });
          }
        }
      }

      if (
        editForm.isThursday.isEdit ||
        editForm.startThursday.isEdit ||
        editForm.endThursday.isEdit
      ) {
        if (editForm.idThursday.value) {
          if (!editForm.isThursday.value) {
            deleteAccessTimeByIdRequest({ id: editForm.idThursday.value });
          } else {
            patchAccessTimeByIdRequest({
              id: editForm.idThursday.value,
              data: {
                start_time: editForm.startThursday.value + ":00",
                end_time: editForm.endThursday.value + ":00",
                weekday: 4,
                group: id
              }
            });
          }
        } else {
          if (editForm.isThursday.value) {
            createAccessTimeRequest({
              data: {
                start_time: editForm.startThursday.value + ":00",
                end_time: editForm.endThursday.value + ":00",
                weekday: 4,
                group: id
              }
            });
          }
        }
      }

      if (
        editForm.isFriday.isEdit ||
        editForm.startFriday.isEdit ||
        editForm.endFriday.isEdit
      ) {
        if (editForm.idFriday.value) {
          if (!editForm.isFriday.value) {
            deleteAccessTimeByIdRequest({ id: editForm.idFriday.value });
          } else {
            patchAccessTimeByIdRequest({
              id: editForm.idFriday.value,
              data: {
                start_time: editForm.startFriday.value + ":00",
                end_time: editForm.endFriday.value + ":00",
                weekday: 5,
                group: id
              }
            });
          }
        } else {
          if (editForm.isFriday.value) {
            createAccessTimeRequest({
              data: {
                start_time: editForm.startFriday.value + ":00",
                end_time: editForm.endFriday.value + ":00",
                weekday: 5,
                group: id
              }
            });
          }
        }
      }

      if (
        editForm.isSaturday.isEdit ||
        editForm.startSaturday.isEdit ||
        editForm.endSaturday.isEdit
      ) {
        if (editForm.idSaturday.value) {
          if (!editForm.isSaturday.value) {
            deleteAccessTimeByIdRequest({ id: editForm.idSaturday.value });
          } else {
            patchAccessTimeByIdRequest({
              id: editForm.idSaturday.value,
              data: {
                start_time: editForm.startSaturday.value + ":00",
                end_time: editForm.endSaturday.value + ":00",
                weekday: 6,
                group: id
              }
            });
          }
        } else {
          if (editForm.isSaturday.value) {
            createAccessTimeRequest({
              data: {
                start_time: editForm.startSaturday.value + ":00",
                end_time: editForm.endSaturday.value + ":00",
                weekday: 6,
                group: id
              }
            });
          }
        }
      }
      if (
        editForm.isSunday.isEdit ||
        editForm.startSunday.isEdit ||
        editForm.endSunday.isEdit
      ) {
        if (editForm.idSunday.value) {
          if (!editForm.isSunday.value) {
            deleteAccessTimeByIdRequest({ id: editForm.idSunday.value });
          } else {
            patchAccessTimeByIdRequest({
              id: editForm.idSunday.value,
              data: {
                start_time: editForm.startSunday.value + ":00",
                end_time: editForm.endSunday.value + ":00",
                weekday: 7,
                group: id
              }
            });
          }
        } else {
          if (editForm.isSunday.value) {
            createAccessTimeRequest({
              data: {
                start_time: editForm.startSunday.value + ":00",
                end_time: editForm.endSunday.value + ":00",
                weekday: 7,
                group: id
              }
            });
          }
        }
      }
    }

    const editProm = patchAccessGroupByIdRequest({ id, data: editedData });
    // editProm.then(() => {
    //   router.go(-1);
    // });
    return editProm;
  },

  async addAllControllersToAccessGroup(context, { id, data }) {
    return new Promise((resolve, reject) => {
      patchAccessGroupByIdRequest({
        id,
        data
      })
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  prepareEditUserGroup(state, payload) {
    Object.keys(state.editUserGroupForm).forEach(key => {
      state.editUserGroupForm[key].value = payload[key];
      state.editUserGroupForm[key].isEdit = false;
    });
  },
  changeEditUserGroupValue(state, { fieldName, value }) {
    state.editUserGroupForm[fieldName].value = value;
    state.editUserGroupForm[fieldName].isEdit = true;
  },
  setGroupInfo(state, { listName, groups }) {
    state[listName].results = groups;
  },
  clearCreateUserGroupForm(state) {
    state.createUserGroupForm = {
      duration: null,
      isMonday: false,
      isTuesday: false,
      isWednesday: false,
      isThursday: false,
      isFriday: false,
      isSaturday: false,
      isSunday: false,
      startMonday: null,
      startTuesday: null,
      startWednesday: null,
      startThursday: null,
      startFriday: null,
      startSaturday: null,
      startSunday: null,
      endMonday: null,
      endTuesday: null,
      endWednesday: null,
      endThursday: null,
      endFriday: null,
      endSaturday: null,
      endSunday: null,
      startTime: null,
      endTime: null,
      title: "",
      timeType: null,
      organization: null,
      object: null,
      role: 110,
      // appPrice: null,
      controllers: [],
      is_all: true,
      cardPrices: null,
      app_price_3: null,
      app_price_12: null
    };
  },
  setCreateUserGroupForm(state, { fieldName, value }) {
    Vue.set(state.createUserGroupForm, fieldName, value);
  },
  setGroupList(state, { listName, groups }) {
    state[listName] = groups;
  },
  addUserSearch(state, { listName, value }) {
    state[listName].results.push(value);
  },
  removeUserSearch(state, { listName, index }) {
    state[listName].results.splice(index, 1);
  },
  setValueUserSearch(state, { listName, value }) {
    state[listName].text = value;
  },
  setUserSearch(state, { listName, group }) {
    state[listName] = group;
  },
  clearGroupList(state, { listName }) {
    state[listName].results = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
